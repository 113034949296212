<template>
  <div class="search">
    <div class="head">搜索的结果 <span>{{ searchVal }}</span></div>
    <div class="list">
      <div v-for="item in list" :key="item.id" class="item" @click="goDetail(item.id)">
        <div class="img"><img :src="item.img_url"></div>
        <div class="cont">
          <div class="title" v-html="item.name" />
          <div class="des" v-html="item.desc" />
          <div class="link">
            <span>查看详情</span> <span class="line">|</span> <span @click.stop="goTech(item)">技术支持</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/index'
export default {
  data() {
    return {
      searchVal: '',
      list: [],
      page: 1,
      finished: false,
      loading: false
    }
  },
  watch: {
    '$route': {
      handler() {
        this.searchVal = this.$route.query.searchVal
        this.page = 1
        this.finished = false
        this.loading = false
        this.list = []
        this.getList()
      },
      immediate: true
    }
  },
  methods: {
    goDetail(id) {
      this.$router.push({ path: '/productDetail', query: { pageId: id }})
    },
    goTech(item) {
      if (item.technical_support_link) {
        window.location.href = item.technical_support_link
      } else {
        this.$router.push({ path: '/productDetail', query: { pageId: item.id, activeTab: 4 }})
      }
    },
    async getList() {
      const res = await getList('product', { keyword: this.searchVal, page: this.page })
      this.list = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  padding: 15px;
  .head {
    font-size: 16px;
    span {
      color: #FF0000;
    }
  }
  .list {
    padding-top: 15px;
    .item {
      display: flex;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #1A1A1A;
      background: #111111;
      .img {
        width: 80px;
        height: 80px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .cont {
        flex: 1;
        padding-left: 10px;
        .title {
          font-size: 14px;
          padding-bottom:5px;
        }
        .red {
          color: #FF0000;
        }
        .des {
          font-size: 9px;
          color: #999999;
          line-height: 15px;
        }
        .link {
          padding-top: 5px;
          font-size: 10px;
          .line {
            padding: 0 10px;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
